@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Belleza%3Aital%2Cwght%400%2C400&family=Lato%3Aital%2Cwght%400%2C100%3B0%2C300%3B0%2C400%3B0%2C700%3B0%2C900%3B1%2C100%3B1%2C300%3B1%2C400%3B1%2C700%3B1%2C900&family=Nanum+Gothic%3Aital%2Cwght%400%2C400%3B0%2C700%3B0%2C800&family=Aboreto%3Aital%2Cwght%400%2C400&display=swap");

.plasmic_tokens {
  --token-cnSh1pgXvO5W: 1px;
  --plasmic-token-unnamed-style-token: var(--token-cnSh1pgXvO5W);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-JLWCzDEV8Hqx604_font-family: "Inter", sans-serif;
  --mixin-JLWCzDEV8Hqx604_font-size: 16px;
  --mixin-JLWCzDEV8Hqx604_font-weight: 400;
  --mixin-JLWCzDEV8Hqx604_font-style: normal;
  --mixin-JLWCzDEV8Hqx604_color: #535353;
  --mixin-JLWCzDEV8Hqx604_text-align: left;
  --mixin-JLWCzDEV8Hqx604_text-transform: none;
  --mixin-JLWCzDEV8Hqx604_line-height: 1.5;
  --mixin-JLWCzDEV8Hqx604_letter-spacing: normal;
  --mixin-JLWCzDEV8Hqx604_white-space: pre-wrap;
  --mixin-JLWCzDEV8Hqx604_user-select: text;
  --mixin-JLWCzDEV8Hqx604_text-decoration-line: none;
  --mixin-JLWCzDEV8Hqx604_text-overflow: clip;
  --mixin-KQxnQS2LOTtNX3V_color: #000000;
  --mixin-KQxnQS2LOTtNX3V_font-weight: 900;
  --mixin-KQxnQS2LOTtNX3V_font-size: 72px;
  --mixin-KQxnQS2LOTtNX3V_line-height: 1;
  --mixin-KQxnQS2LOTtNX3V_letter-spacing: -4px;
  --mixin-KQxnQS2LOTtNX3V_white-space: pre-wrap;
  --mixin-KTYHB0w-hxfBABD_color: #000000;
  --mixin-KTYHB0w-hxfBABD_font-size: 48px;
  --mixin-KTYHB0w-hxfBABD_font-weight: 700;
  --mixin-KTYHB0w-hxfBABD_letter-spacing: -1px;
  --mixin-KTYHB0w-hxfBABD_line-height: 1.1;
  --mixin-KTYHB0w-hxfBABD_white-space: pre-wrap;
  --mixin-ENDHItgw7HQELQR_color: #0070f3;
  --mixin-ENDHItgw7HQELQR_white-space: pre-wrap;
  --mixin-WIA1ggOXh27mXvH_color: #000000;
  --mixin-WIA1ggOXh27mXvH_font-size: 32px;
  --mixin-WIA1ggOXh27mXvH_font-weight: 600;
  --mixin-WIA1ggOXh27mXvH_letter-spacing: -0.8px;
  --mixin-WIA1ggOXh27mXvH_line-height: 1.2;
  --mixin-WIA1ggOXh27mXvH_white-space: pre-wrap;
  --mixin-FDBYQL9q1_cgQQG_color: #000000;
  --mixin-FDBYQL9q1_cgQQG_font-size: 24px;
  --mixin-FDBYQL9q1_cgQQG_font-weight: 600;
  --mixin-FDBYQL9q1_cgQQG_letter-spacing: -0.5px;
  --mixin-FDBYQL9q1_cgQQG_line-height: 1.3;
  --mixin-FDBYQL9q1_cgQQG_white-space: pre-wrap;
  --mixin-cccOG8VZrGOO5kU_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-cccOG8VZrGOO5kU_border-bottom-color: #dddddd;
  --mixin-cccOG8VZrGOO5kU_border-bottom-style: solid;
  --mixin-cccOG8VZrGOO5kU_border-bottom-width: 1px;
  --mixin-cccOG8VZrGOO5kU_border-left-color: #dddddd;
  --mixin-cccOG8VZrGOO5kU_border-left-style: solid;
  --mixin-cccOG8VZrGOO5kU_border-left-width: 1px;
  --mixin-cccOG8VZrGOO5kU_border-right-color: #dddddd;
  --mixin-cccOG8VZrGOO5kU_border-right-style: solid;
  --mixin-cccOG8VZrGOO5kU_border-right-width: 1px;
  --mixin-cccOG8VZrGOO5kU_border-top-color: #dddddd;
  --mixin-cccOG8VZrGOO5kU_border-top-style: solid;
  --mixin-cccOG8VZrGOO5kU_border-top-width: 1px;
  --mixin-cccOG8VZrGOO5kU_border-bottom-left-radius: 3px;
  --mixin-cccOG8VZrGOO5kU_border-bottom-right-radius: 3px;
  --mixin-cccOG8VZrGOO5kU_border-top-left-radius: 3px;
  --mixin-cccOG8VZrGOO5kU_border-top-right-radius: 3px;
  --mixin-cccOG8VZrGOO5kU_font-family: "Inconsolata";
  --mixin-cccOG8VZrGOO5kU_padding-bottom: 1px;
  --mixin-cccOG8VZrGOO5kU_padding-left: 4px;
  --mixin-cccOG8VZrGOO5kU_padding-right: 4px;
  --mixin-cccOG8VZrGOO5kU_padding-top: 1px;
  --mixin-cccOG8VZrGOO5kU_white-space: pre-wrap;
  --mixin-gXoamaCaELAg5Fi_border-left-color: #dddddd;
  --mixin-gXoamaCaELAg5Fi_border-left-style: solid;
  --mixin-gXoamaCaELAg5Fi_border-left-width: 3px;
  --mixin-gXoamaCaELAg5Fi_color: #888888;
  --mixin-gXoamaCaELAg5Fi_padding-left: 10px;
  --mixin-gXoamaCaELAg5Fi_white-space: pre-wrap;
  --mixin-Ufe0xrf2HiN6xs1_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Ufe0xrf2HiN6xs1_border-bottom-color: #dddddd;
  --mixin-Ufe0xrf2HiN6xs1_border-bottom-style: solid;
  --mixin-Ufe0xrf2HiN6xs1_border-bottom-width: 1px;
  --mixin-Ufe0xrf2HiN6xs1_border-left-color: #dddddd;
  --mixin-Ufe0xrf2HiN6xs1_border-left-style: solid;
  --mixin-Ufe0xrf2HiN6xs1_border-left-width: 1px;
  --mixin-Ufe0xrf2HiN6xs1_border-right-color: #dddddd;
  --mixin-Ufe0xrf2HiN6xs1_border-right-style: solid;
  --mixin-Ufe0xrf2HiN6xs1_border-right-width: 1px;
  --mixin-Ufe0xrf2HiN6xs1_border-top-color: #dddddd;
  --mixin-Ufe0xrf2HiN6xs1_border-top-style: solid;
  --mixin-Ufe0xrf2HiN6xs1_border-top-width: 1px;
  --mixin-Ufe0xrf2HiN6xs1_border-bottom-left-radius: 3px;
  --mixin-Ufe0xrf2HiN6xs1_border-bottom-right-radius: 3px;
  --mixin-Ufe0xrf2HiN6xs1_border-top-left-radius: 3px;
  --mixin-Ufe0xrf2HiN6xs1_border-top-right-radius: 3px;
  --mixin-Ufe0xrf2HiN6xs1_font-family: "Inconsolata";
  --mixin-Ufe0xrf2HiN6xs1_padding-bottom: 3px;
  --mixin-Ufe0xrf2HiN6xs1_padding-left: 6px;
  --mixin-Ufe0xrf2HiN6xs1_padding-right: 6px;
  --mixin-Ufe0xrf2HiN6xs1_padding-top: 3px;
  --mixin-Ufe0xrf2HiN6xs1_white-space: pre-wrap;
  --mixin-OYDGH4NvhagPv_R_display: flex;
  --mixin-OYDGH4NvhagPv_R_flex-direction: column;
  --mixin-OYDGH4NvhagPv_R_align-items: stretch;
  --mixin-OYDGH4NvhagPv_R_justify-content: flex-start;
  --mixin-OYDGH4NvhagPv_R_list-style-position: outside;
  --mixin-OYDGH4NvhagPv_R_padding-left: 40px;
  --mixin-OYDGH4NvhagPv_R_position: relative;
  --mixin-OYDGH4NvhagPv_R_list-style-type: disc;
  --mixin-OYDGH4NvhagPv_R_white-space: pre-wrap;
  --mixin-3CrMh6dpR3QP7MK_display: flex;
  --mixin-3CrMh6dpR3QP7MK_flex-direction: column;
  --mixin-3CrMh6dpR3QP7MK_align-items: stretch;
  --mixin-3CrMh6dpR3QP7MK_justify-content: flex-start;
  --mixin-3CrMh6dpR3QP7MK_list-style-position: outside;
  --mixin-3CrMh6dpR3QP7MK_padding-left: 40px;
  --mixin-3CrMh6dpR3QP7MK_position: relative;
  --mixin-3CrMh6dpR3QP7MK_list-style-type: decimal;
  --mixin-3CrMh6dpR3QP7MK_white-space: pre-wrap;
  --mixin-Tqx7CkOEll1Taqd_color: #000000;
  --mixin-Tqx7CkOEll1Taqd_font-size: 20px;
  --mixin-Tqx7CkOEll1Taqd_font-weight: 600;
  --mixin-Tqx7CkOEll1Taqd_letter-spacing: -0.3px;
  --mixin-Tqx7CkOEll1Taqd_line-height: 1.5;
  --mixin-Tqx7CkOEll1Taqd_white-space: pre-wrap;
  --mixin-Y6LvblU7PSMubIo_color: #000000;
  --mixin-Y6LvblU7PSMubIo_font-size: 16px;
  --mixin-Y6LvblU7PSMubIo_font-weight: 600;
  --mixin-Y6LvblU7PSMubIo_line-height: 1.5;
  --mixin-Y6LvblU7PSMubIo_white-space: pre-wrap;
  --mixin-iw6tD4xaNr_DW6W_color: #3291ff;
  --mixin-iw6tD4xaNr_DW6W_white-space: pre-wrap;
  --mixin-Gb0dckRQTDImDNb_white-space: pre-wrap;
  --mixin-LoBETxM8RiAGigE_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-JLWCzDEV8Hqx604_font-family);
  font-size: var(--mixin-JLWCzDEV8Hqx604_font-size);
  font-weight: var(--mixin-JLWCzDEV8Hqx604_font-weight);
  font-style: var(--mixin-JLWCzDEV8Hqx604_font-style);
  color: var(--mixin-JLWCzDEV8Hqx604_color);
  text-align: var(--mixin-JLWCzDEV8Hqx604_text-align);
  text-transform: var(--mixin-JLWCzDEV8Hqx604_text-transform);
  line-height: var(--mixin-JLWCzDEV8Hqx604_line-height);
  letter-spacing: var(--mixin-JLWCzDEV8Hqx604_letter-spacing);
  white-space: var(--mixin-JLWCzDEV8Hqx604_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-KQxnQS2LOTtNX3V_color);
  font-weight: var(--mixin-KQxnQS2LOTtNX3V_font-weight);
  font-size: var(--mixin-KQxnQS2LOTtNX3V_font-size);
  line-height: var(--mixin-KQxnQS2LOTtNX3V_line-height);
  letter-spacing: var(--mixin-KQxnQS2LOTtNX3V_letter-spacing);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-KTYHB0w-hxfBABD_color);
  font-size: var(--mixin-KTYHB0w-hxfBABD_font-size);
  font-weight: var(--mixin-KTYHB0w-hxfBABD_font-weight);
  letter-spacing: var(--mixin-KTYHB0w-hxfBABD_letter-spacing);
  line-height: var(--mixin-KTYHB0w-hxfBABD_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-ENDHItgw7HQELQR_color);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  color: var(--mixin-WIA1ggOXh27mXvH_color);
  font-size: var(--mixin-WIA1ggOXh27mXvH_font-size);
  font-weight: var(--mixin-WIA1ggOXh27mXvH_font-weight);
  letter-spacing: var(--mixin-WIA1ggOXh27mXvH_letter-spacing);
  line-height: var(--mixin-WIA1ggOXh27mXvH_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  color: var(--mixin-FDBYQL9q1_cgQQG_color);
  font-size: var(--mixin-FDBYQL9q1_cgQQG_font-size);
  font-weight: var(--mixin-FDBYQL9q1_cgQQG_font-weight);
  letter-spacing: var(--mixin-FDBYQL9q1_cgQQG_letter-spacing);
  line-height: var(--mixin-FDBYQL9q1_cgQQG_line-height);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-cccOG8VZrGOO5kU_font-family);
  border-radius: var(--mixin-cccOG8VZrGOO5kU_border-top-left-radius)
    var(--mixin-cccOG8VZrGOO5kU_border-top-right-radius)
    var(--mixin-cccOG8VZrGOO5kU_border-bottom-right-radius)
    var(--mixin-cccOG8VZrGOO5kU_border-bottom-left-radius);
  padding: var(--mixin-cccOG8VZrGOO5kU_padding-top)
    var(--mixin-cccOG8VZrGOO5kU_padding-right)
    var(--mixin-cccOG8VZrGOO5kU_padding-bottom)
    var(--mixin-cccOG8VZrGOO5kU_padding-left);
  border-top: var(--mixin-cccOG8VZrGOO5kU_border-top-width)
    var(--mixin-cccOG8VZrGOO5kU_border-top-style)
    var(--mixin-cccOG8VZrGOO5kU_border-top-color);
  border-right: var(--mixin-cccOG8VZrGOO5kU_border-right-width)
    var(--mixin-cccOG8VZrGOO5kU_border-right-style)
    var(--mixin-cccOG8VZrGOO5kU_border-right-color);
  border-bottom: var(--mixin-cccOG8VZrGOO5kU_border-bottom-width)
    var(--mixin-cccOG8VZrGOO5kU_border-bottom-style)
    var(--mixin-cccOG8VZrGOO5kU_border-bottom-color);
  border-left: var(--mixin-cccOG8VZrGOO5kU_border-left-width)
    var(--mixin-cccOG8VZrGOO5kU_border-left-style)
    var(--mixin-cccOG8VZrGOO5kU_border-left-color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-gXoamaCaELAg5Fi_color);
  padding-left: var(--mixin-gXoamaCaELAg5Fi_padding-left);
  border-left: var(--mixin-gXoamaCaELAg5Fi_border-left-width)
    var(--mixin-gXoamaCaELAg5Fi_border-left-style)
    var(--mixin-gXoamaCaELAg5Fi_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-Ufe0xrf2HiN6xs1_font-family);
  border-radius: var(--mixin-Ufe0xrf2HiN6xs1_border-top-left-radius)
    var(--mixin-Ufe0xrf2HiN6xs1_border-top-right-radius)
    var(--mixin-Ufe0xrf2HiN6xs1_border-bottom-right-radius)
    var(--mixin-Ufe0xrf2HiN6xs1_border-bottom-left-radius);
  padding: var(--mixin-Ufe0xrf2HiN6xs1_padding-top)
    var(--mixin-Ufe0xrf2HiN6xs1_padding-right)
    var(--mixin-Ufe0xrf2HiN6xs1_padding-bottom)
    var(--mixin-Ufe0xrf2HiN6xs1_padding-left);
  border-top: var(--mixin-Ufe0xrf2HiN6xs1_border-top-width)
    var(--mixin-Ufe0xrf2HiN6xs1_border-top-style)
    var(--mixin-Ufe0xrf2HiN6xs1_border-top-color);
  border-right: var(--mixin-Ufe0xrf2HiN6xs1_border-right-width)
    var(--mixin-Ufe0xrf2HiN6xs1_border-right-style)
    var(--mixin-Ufe0xrf2HiN6xs1_border-right-color);
  border-bottom: var(--mixin-Ufe0xrf2HiN6xs1_border-bottom-width)
    var(--mixin-Ufe0xrf2HiN6xs1_border-bottom-style)
    var(--mixin-Ufe0xrf2HiN6xs1_border-bottom-color);
  border-left: var(--mixin-Ufe0xrf2HiN6xs1_border-left-width)
    var(--mixin-Ufe0xrf2HiN6xs1_border-left-style)
    var(--mixin-Ufe0xrf2HiN6xs1_border-left-color);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-OYDGH4NvhagPv_R_display);
  flex-direction: var(--mixin-OYDGH4NvhagPv_R_flex-direction);
  align-items: var(--mixin-OYDGH4NvhagPv_R_align-items);
  justify-content: var(--mixin-OYDGH4NvhagPv_R_justify-content);
  list-style-position: var(--mixin-OYDGH4NvhagPv_R_list-style-position);
  padding-left: var(--mixin-OYDGH4NvhagPv_R_padding-left);
  position: var(--mixin-OYDGH4NvhagPv_R_position);
  list-style-type: var(--mixin-OYDGH4NvhagPv_R_list-style-type);
  flex-column-gap: var(--mixin-OYDGH4NvhagPv_R_flex-column-gap);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-3CrMh6dpR3QP7MK_display);
  flex-direction: var(--mixin-3CrMh6dpR3QP7MK_flex-direction);
  align-items: var(--mixin-3CrMh6dpR3QP7MK_align-items);
  justify-content: var(--mixin-3CrMh6dpR3QP7MK_justify-content);
  list-style-position: var(--mixin-3CrMh6dpR3QP7MK_list-style-position);
  padding-left: var(--mixin-3CrMh6dpR3QP7MK_padding-left);
  position: var(--mixin-3CrMh6dpR3QP7MK_position);
  list-style-type: var(--mixin-3CrMh6dpR3QP7MK_list-style-type);
  flex-column-gap: var(--mixin-3CrMh6dpR3QP7MK_flex-column-gap);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  color: var(--mixin-Tqx7CkOEll1Taqd_color);
  font-size: var(--mixin-Tqx7CkOEll1Taqd_font-size);
  font-weight: var(--mixin-Tqx7CkOEll1Taqd_font-weight);
  letter-spacing: var(--mixin-Tqx7CkOEll1Taqd_letter-spacing);
  line-height: var(--mixin-Tqx7CkOEll1Taqd_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  color: var(--mixin-Y6LvblU7PSMubIo_color);
  font-size: var(--mixin-Y6LvblU7PSMubIo_font-size);
  font-weight: var(--mixin-Y6LvblU7PSMubIo_font-weight);
  line-height: var(--mixin-Y6LvblU7PSMubIo_line-height);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-iw6tD4xaNr_DW6W_color);
}

:where(.root_reset) li:where(.li),
li:where(.root_reset.li),
:where(.root_reset .__wab_expr_html_text) li,
:where(.root_reset_tags) li,
li:where(.root_reset_tags) {
}

:where(.root_reset) p:where(.p),
p:where(.root_reset.p),
:where(.root_reset .__wab_expr_html_text) p,
:where(.root_reset_tags) p,
p:where(.root_reset_tags) {
}
