.parent {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  background: url("./images/background1Png.png") top auto left auto / cover repeat;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .parent {
    justify-content: center;
    align-items: center;
  }
}
.navigationBar:global(.__wab_instance) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  padding: 16px;
}
.link__q7Bro {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.img__j53Y2 {
  object-fit: cover;
  height: 40px;
}
.img__j53Y2 > picture > img {
  object-fit: cover;
}
.img__j53Y2 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__oAyh0 {
  object-fit: cover;
}
.img__oAyh0 > picture > img {
  object-fit: cover;
}
.img__oAyh0 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__yaNY {
  object-fit: cover;
}
.img__yaNY > picture > img {
  object-fit: cover;
}
.img__yaNY > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.link__roy5B {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
}
.link__ok8S2 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
}
.link__l1Xl0 {
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
}
.sliderCarousel:global(.__wab_instance) {
  width: 100vw;
  max-width: 100vw;
  flex-direction: column;
  object-fit: cover;
  position: relative;
  height: 100%;
  min-height: 0;
  margin: 0px 0px -263px;
}
.freeBox__xQrIe {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.img__aAhxn {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  min-width: 0;
  flex-shrink: 0;
}
.img__aAhxn > picture > img {
  object-fit: cover;
}
.img__aAhxn > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__aAhxn {
    width: 100%;
    min-width: 0;
  }
}
.freeBox__up7Un {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.img__kdqiP {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0px;
  height: 100vh;
  min-width: 0;
  flex-shrink: 0;
}
.img__kdqiP > picture > img {
  object-fit: cover;
}
.img__kdqiP > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__kdqiP {
    width: 100%;
    min-width: 0;
  }
}
.freeBox__zMhEp {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.img__aQyWg {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0px;
  height: 100vh;
  min-width: 0;
  flex-shrink: 0;
}
.img__aQyWg > picture > img {
  object-fit: cover;
}
.img__aQyWg > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__aQyWg {
    width: 100vw;
    max-width: 100vw;
  }
}
.freeBox__xyGnP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  margin-top: 0px;
  min-width: 0;
  padding: 8px 8px 0px;
}
.text__mrCjm {
  width: 100%;
  height: auto;
  max-width: 100%;
  text-align: left;
  font-size: 66px;
  font-family: "Belleza", sans-serif;
  color: #000000;
  min-height: 200px;
  padding-left: 61px;
  padding-bottom: 47px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__mrCjm {
    font-size: 3em;
    padding-top: 24px;
  }
}
.hoursMenus {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 57px;
  min-width: 0;
  padding: 8px;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__tgGvb {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.text__rPjmM {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-size: 36px;
  text-align: center;
  color: #000000;
  font-family: "Belleza", sans-serif;
  min-width: 0;
}
.text__s6U9 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-size: 18px;
  text-align: center;
  font-family: "Belleza", sans-serif;
  min-width: 0;
}
.text___6P16W {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-size: 36px;
  text-align: center;
  color: #000000;
  font-family: "Belleza", sans-serif;
  margin-top: 28px;
  min-width: 0;
}
.text__jvEcA {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-top: 0px;
  font-size: 18px;
  text-align: center;
  font-family: "Belleza", sans-serif;
  min-width: 0;
}
.column__pKUno {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.contact {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
        0deg,
        #000000 0%,
        #4040404d 12%,
        #060b0640 49%,
        #2323234d 92%,
        #000000 100%
      )
      0% 0% / 100% 100% repeat,
    url("./images/_7GyNvsfMpng.png") top 50% left 50% / cover repeat;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .contact {
    margin-top: 0px;
    height: auto;
    align-items: center;
    justify-content: center;
  }
}
.contactParent {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  background: none;
  margin-top: 0px;
  min-width: 0;
  padding: 0px 0px 0px 40px;
}
.contactParent > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
  --plsmc-rc-col-gap: 40px;
}
.contactParent > :global(.__wab_flex-container) > *,
.contactParent > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.contactParent > :global(.__wab_flex-container) > picture > img,
.contactParent
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.contactParent {
  --plsmc-rc-col-gap: 0px;
}
.contactParent > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.contactParent > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .contactParent {
    width: 100%;
    height: 100%;
    flex-direction: row;
    min-width: 0;
    min-height: 0;
    padding: 0px 0px 0px 1px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .contactParent > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    min-height: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .contactParent > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.contact2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 0px 8px 8px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .contact2 {
    padding: 0px;
  }
}
.form {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 644px;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .form {
    height: auto;
    padding: 0px;
  }
}
.text__yy4Ja {
  position: relative;
  width: auto;
  height: auto;
  max-width: 589px;
  color: #ffffff;
  font-size: 44px;
  text-align: left;
  margin-top: 21px;
  margin-bottom: 40px;
  font-family: "Belleza", sans-serif;
  display: block;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__yy4Ja {
    padding-left: 17px;
  }
}
.text__x7EPg {
  position: relative;
  width: auto;
  height: auto;
  max-width: 589px;
  color: #ffffff;
  font-size: 25px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 40px;
  font-family: "Belleza", sans-serif;
  padding-top: 33px;
  display: block;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__x7EPg {
    padding-top: 0px;
    padding-left: 17px;
  }
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 20px;
  display: none;
}
.svg__zi2HK {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___47Xe {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.mapAndInfo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mapAndInfo {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0px;
  }
}
.link__h0YEn {
  position: relative;
  align-self: flex-start;
  margin-top: 0px;
  display: flex;
  cursor: pointer;
}
.img__pYbDq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  margin-left: 1px;
  width: 100%;
  height: 489px;
}
.img__pYbDq > picture > img {
  object-fit: cover;
}
.img__pYbDq > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__pYbDq {
    width: 100%;
    margin-left: 0px;
    height: 100%;
    min-width: 0;
    min-height: 0;
  }
}
.footer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: none;
  min-width: 0;
  padding: 40px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer {
    height: auto;
    overflow: visible;
    padding: 2px 0px 17px;
  }
}
.footerParentColumns {
  --plsmc-rc-col-gap: 0px;
}
.footerParentColumns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.footerParentColumns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
.footerParentColumns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerParentColumns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerParentColumns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__brCgF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__brCgF {
    align-items: center;
    justify-content: center;
    overflow: visible;
    padding: 50px 0px;
  }
}
.logo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 50%;
}
.logo > picture > img {
  object-fit: cover;
}
.logo > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__kIujZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px 40px 8px 8px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .column__kIujZ {
    padding: 0px;
  }
}
.text__urCoz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-family: "Belleza", sans-serif;
  font-weight: 200;
  text-align: right;
  font-size: 20px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__urCoz {
    text-align: center;
    font-weight: 400;
    padding-bottom: 10px;
    display: block;
  }
}
.text___3Rg9Q {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  font-family: "Lato", sans-serif;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___3Rg9Q {
    text-align: center;
  }
}
.link___2Jc2H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  font-family: "Lato", sans-serif;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link___2Jc2H {
    text-align: center;
  }
}
.parent .link___2Jc2H:hover {
  color: var(--token-zy6xhYnWF8Y7);
}
